<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <!-- main header -->
    <div class="lter b-b py-5 ps-4 bg-white box-shadow">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h3 class="m-n font-medium"> Govt. Section Wise Report</h3>
        </div>
        <div class="col-sm-6 text-right poppins hidden-xs">

        </div>
      </div>
    </div>
    <!-- / main header -->
    <div class="row mw">
      <div class="col-md-12">
        <div class="card no-shadow">
          <div class="tab-content bg-seashell">
            <div role="tabpanel" class="tab-pane active g-py-0">
              <div class="row row-xs">

                <div class="row">

                  <div class="col-md-4">
                    <div class="input_grp form-group">
                      <label for="">GOVT. SECTION / शासन अनुभाग </label>
                      <select class="form-control select-language form-control-sm validation" id="department"
                        (change)="getDataDepartment($event)">
                        <option value=''>Select</option>
                        <option *ngFor="let data of getDataSect" value={{data.section_name}}>{{data.section_name}}
                        </option>
                      </select>

                    </div>
                  </div>

                </div>


              </div>
            </div>
            <div role="tabpanel" class="tab-pane active g-py-0">
              <div class="row row-xs">
                <table class="table bg-white newTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  id="portal-drivers">
                  <thead>
                    <tr role="row">
                      <th class="text-center">SI. No. / क्र सं०</th>
                      <th>GOVT. SECTION / शासन अनुभाग</th>
                      <th style="width: 4rem;">DEPARTMENT / विभाग</th>
                      <th>CASE YEAR / वाद वर्ष</th>
                      <th>CASE No. / वाद संख्या</th>
                      <th>COURT / मा0 न्यायालय</th>
                      <th>ORGANIZATION / CE</th>
                      <th>SE </th>
                      <th>EE</th>
                      <th>CASE TYPE / वाद प्रकार</th>
                      <th>CASE SUBJECT / वाद विषय </th>
                      <th>PETITIONER / वादीगण</th>
                      <th>RESPONDENT / प्रतिवादीगण</th>
                      <!-- <th>Case Description / प्रकरण संक्षिप्त विवरण</th> -->

                      <th>DISTRICT / जिला</th>

                      <th>H.O. SECTION / CELL</th>
                      <th>AFFIDAVIT FILING DATE / प्रतिशपथ पत्र दाख़िल कराने की तिथि</th>
                      <th>REJOINDER</th>
                      <th>REJOINDER DATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row" *ngFor="let data of getData; let i= index;">
                      <td class="text-center">{{ [i+1] }}</td>
                      <td>{{data?.sectionData[0]?.section_name }}</td>
                      <td style="width: 4rem;">{{data?.orgData[0]?.org_name}}</td>
                      <td>{{data?.case_year}}</td>
                      <td>{{data?.case_no}}</td>
                      <td>{{data?.courtData[0]?.court_name}}</td>
                      <td>{{data?.suborgData [0]?.sub_org_name}}</td>
                      <td>{{data?.seData[0]?.se_name}} </td>
                      <td>{{data?.eeCollection[0]?.ee_name }}</td>
                      <td>{{data?.caseTypeData[0]?.case_type_name }}</td>
                      <td>{{data?.subjectData[0]?.case_subject_name}}</td>
                      <td>
                        <ul>
                          <li *ngFor="let data of data?.petitionerCollection" style="margin: 0 0 -2px;">
                            {{data.name}}
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li *ngFor="let data of data?.respondentsCollection" style="margin: 0 0 -2px;">
                            {{data.res_name}}
                          </li>
                        </ul>
                      </td>
                      <!-- <td>{{data?.case_description}}</td> -->

                      <td>{{data?.districtData[0]?.district}}</td>

                      <td>{{data?.hosectionData[0]?.ho_name}}</td>
                      <td>{{data?.case_affidavit_filing_date}}</td>
                      <td>{{data?.case_rejoinder}}</td>
                      <td>{{data?.case_rejoinder_date}}</td>
                    </tr>

                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>



<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>