import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/services/toaster.service';
import { MasterService } from 'src/app/services/master.service';
import { CaseService } from 'src/app/services/case.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RolePermissionService } from 'src/app/services/role-permission.service';

@Component({
  selector: "app-update-user",
  templateUrl: "./update-user.component.html",
  styleUrls: ["./update-user.component.css"],
})
export class UpdateUserComponent implements OnInit {
  saveForm: any;
  getDataS: any;
  getDataOrg: any;
  getDataEE: any;
  getDataDist: any;
  getDataDept: any;
  isValidFormSubmitted!: boolean;
  isPasswordSame: any;
  id: any;
  EditData: any;
  district: any;
  username: any;
  router: any;
  Udata: any;
  getDataRoles: any;
  subOrgDataByOrg: any;
  seDataBySubOrg: any;
  EeDataBySe: any;
  getDataO: any;
  roleData: any;
  constructor(
    private fb: UntypedFormBuilder,
    private toast: ToasterService,
    private CaseS: CaseService,
    private MasterS: MasterService,
    private AuthS: AuthService,
    private _Activatedroute: ActivatedRoute,
    private RoleS: RolePermissionService,
    private routers: Router
  ) { }

  ngOnInit(): void {

    this.saveForm = this.fb.group({
      username: ['', Validators.required],
      designation: [''],
      // department: [r?.department],
      // section: [r?.section],
      email: [
        '',
        [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")],
      ],
      mobile: ['', [Validators.pattern("^[0-9][0-9]{9}$")]],
      district: ['', Validators.required],

      circle_name: [''],
      office_name: [],
      created_by: [],
      role: [''],
      case_se_id: [""],
      case_sun_org_id: [""],
      case_ee_id: [""],
      case_orgnaization_id: [""],
    });
    this.viewdataRoles();
    this.MasterS.GetOrgdata().subscribe((data: any) => {

      this.getDataO = data.resultData;
    });
    this.username = localStorage.getItem("username")
      ? localStorage.getItem("username")
      : this.router.navigate(["/"]);
    this.Udata = localStorage.getItem("userdata");

    this.id = this._Activatedroute.snapshot.paramMap.get("id");

    this.AuthS.editUser(this.id).subscribe((data: any) => {

      this.EditData = data.updateData[0];
      this.saveForm.get("username").setValue(this.EditData.username);
      this.saveForm.get("designation").setValue(this.EditData.designation);
      this.saveForm.get("email").setValue(this.EditData.email);
      this.saveForm.get("mobile").setValue(this.EditData.mobile);
      this.saveForm.get("district").setValue(this.EditData.district);
      this.saveForm.get("circle_name").setValue(this.EditData.circle_name);
      this.saveForm.get("office_name").setValue(this.EditData.office_name);
      this.saveForm.get("created_by").setValue(this.EditData.created_by);
      this.saveForm.get("role").setValue(this.EditData.role);

      this.saveForm.get("case_orgnaization_id").setValue(this.EditData.case_orgnaization_id);
      this.MasterS.getSubOrgByOrg({
        case_sun_org_id: this.EditData.case_orgnaization_id,
      }).subscribe((data: any) => {

        this.subOrgDataByOrg = data.result;
      });
      this.saveForm.get("case_sun_org_id").setValue(this.EditData.case_sun_org_id);
      this.MasterS.getSeBySubOrgData({
        case_se_id: this.EditData.case_sun_org_id,
      }).subscribe((data: any) => {
        this.seDataBySubOrg = data.result;
      });
      // this.getSeBySubOrgD()
      this.saveForm.get("case_se_id").setValue(this.EditData.case_se_id);
      this.MasterS.getEeBySeData({
        case_ee_id: this.EditData.case_se_id,
      }).subscribe((data: any) => {
        this.EeDataBySe = data.result;
      });
      // this.getEeBySeD()
      this.saveForm.get("case_ee_id").setValue(this.EditData.case_ee_id);
      this.checkRole();




      // this.onforminit(this.EditData);
    });

    this.secData();
    this.OrgData();
    this.viewdataEE();
    this.districtData();
    this.departmentData();

    // this. onforminit();
  }

  viewdataRoles() {
    this.RoleS.GetRolesData().subscribe((data: any) => {
      this.getDataRoles = data.resultData;
    });
  }
  onforminit(r: any) {
    this.saveForm = this.fb.group(
      {
        username: [r?.username, Validators.required],
        designation: [r?.designation],
        email: [
          r?.email,
          [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")],
        ],
        mobile: [r?.mobile, [Validators.pattern("^[0-9][0-9]{9}$")]],
        district: [r?.district, Validators.required],
        orgnization: [r?.orgnization],
        circle_name: [r?.circle_name],
        office_name: [r?.office_name],
        created_by: [this.username],
        role: [r?.role],
        case_se_id: [r?.case_se_id],
        case_sun_org_id: [r?.case_sun_org_id],
        case_ee_id: [r?.case_ee_id],
        case_orgnaization_id: [r?.case_orgnaization_id],
      }
      // { validators: this.checkPassword('password', 'confirm_password') }
    );
    this.checkRole();
  }


  clickFunction() {
    this.isValidFormSubmitted = false;
    let userData;
    if (this.roleData?.role_name === "EE") {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,

        case_se_id: this.saveForm.value.case_se_id,
        case_sun_org_id: this.saveForm.value.case_sun_org_id,
        case_ee_id: this.saveForm.value.case_ee_id,
        case_orgnaization_id: this.saveForm.value.case_orgnaization_id,
      };
    } else if (this.roleData?.role_name === "Department") {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,

        case_orgnaization_id: this.saveForm.value.case_orgnaization_id,
      };
    } else if (this.roleData?.role_name === "Organization") {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,

        case_sun_org_id: this.saveForm.value.case_sun_org_id,
        case_orgnaization_id: this.saveForm.value.case_orgnaization_id,
      };
    } else if (this.roleData?.role_name === "SE") {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,

        case_se_id: this.saveForm.value.case_se_id,
        case_sun_org_id: this.saveForm.value.case_sun_org_id,
        case_orgnaization_id: this.saveForm.value.case_orgnaization_id,
      };
    } else {
      userData = {
        username: this.saveForm.value.username,
        designation: this.saveForm.value.designation,
        // department: ['',Validators.required],
        // section: ['',Validators.required],
        email: this.saveForm.value.email,
        mobile: this.saveForm.value.mobile,
        district: this.saveForm.value.district,
        circle_name: this.saveForm.value.circle_name,
        // ee_name: ['',Validators.required],
        office_name: this.saveForm.value.office_name,
        created_by: this.saveForm.value.created_by,
        role: this.saveForm.value.role,
      };
    }



    if (this.saveForm.invalid) {
      this.toast.showError("Required fields are missing.");
      this.isValidFormSubmitted = true;
    } else {
      this.AuthS.updateUser(userData, this.id).subscribe((data: any) => {
        this.toast.showSuccess("Congratulation!, Data has been submited.");
        // window.location.href = "/list-user";
        this.routers.navigate(['/dashboard/list-user']);
        // this.saveForm.reset()
      });
    }
  }
  get f() {
    return this.saveForm.controls;
  }
  secData() {
    this.MasterS.GetSecdata().subscribe((data: any) => {
      this.getDataS = data.resultData;
    });
  }

  OrgData() {
    this.MasterS.GetSubOrgdata().subscribe((data: any) => {
      this.getDataOrg = data.resultData;
    });
  }

  viewdataEE() {
    this.MasterS.GetEEdata().subscribe((data: any) => {
      this.getDataEE = data.resultData;
    });
  }

  districtData() {
    this.MasterS.GetDistrictata().subscribe((data: any) => {
      this.getDataDist = data.resultData;
    });
  }

  departmentData() {
    this.MasterS.GetOrgdata().subscribe((data: any) => {
      this.getDataDept = data.resultData;
    });
  }

  filter(data: any) {
    this.district = [];
    // let petitionerData= this.saveForm.value.district
    //  // console.log(petitionerData)
    // for(let i of petitionerData){
    //  // console.log(i)
    //  this.MasterS
    //    .GetDistrictata()
    //    .subscribe((data: any) => {
    //      // console.log(data.resultData[0])
    //      this.district.push(data.resultData[0]);
    //      

    //    });
    //  }
  }

  getSubOrgByOrgd() {

    this.MasterS.getSubOrgByOrg({
      case_sun_org_id: this.saveForm.value.case_orgnaization_id,
    }).subscribe((data: any) => {
      this.subOrgDataByOrg = data.result;
    });
    // this.saveFormMain.controls.case_se_id.reset();
    // this.saveFormMain.controls.case_ee_id.reset();
  }

  getSeBySubOrgD() {
    this.MasterS.getSeBySubOrgData({
      case_se_id: this.saveForm.value.case_sun_org_id,
    }).subscribe((data: any) => {
      this.seDataBySubOrg = data.result;
    });

    // this.saveFormMain.controls.case_ee_id.reset();
  }

  getEeBySeD() {

    this.MasterS.getEeBySeData({
      case_ee_id: this.saveForm.value.case_se_id,
    }).subscribe((data: any) => {
      this.EeDataBySe = data.result;
    });
  }
  checkRole() {
    this.getDataRoles?.map((item: any) => {
      if (item._id === this.saveForm.value.role) {
        this.roleData = item;
        if (this.roleData?.role_name === "EE") {
          this.saveForm
            .get("case_orgnaization_id")
            ?.setValidators(Validators.required);
          this.saveForm
            .get("case_sun_org_id")
            ?.setValidators(Validators.required);
          this.saveForm.get("case_se_id")?.setValidators(Validators.required);
          this.saveForm.get("case_ee_id")?.setValidators(Validators.required);
          this.saveForm?.updateValueAndValidity();
        } else if (this.roleData?.role_name === "Department") {
          this.saveForm
            .get("case_orgnaization_id")
            ?.setValidators(Validators.required);
          this.saveForm.get("case_sun_org_id")?.clearValidators();
          this.saveForm.get("case_se_id")?.clearValidators();
          this.saveForm.get("case_ee_id")?.clearValidators();
          this.saveForm?.updateValueAndValidity();
        } else if (this.roleData?.role_name === "Organization") {
          this.saveForm
            .get("case_orgnaization_id")
            ?.setValidators(Validators.required);
          this.saveForm
            .get("case_sun_org_id")
            ?.setValidators(Validators.required);
          this.saveForm.get("case_se_id")?.clearValidators();
          this.saveForm.get("case_ee_id")?.clearValidators();
          this.saveForm?.updateValueAndValidity();
        } else if (this.roleData?.role_name === "SE") {
          this.saveForm
            .get("case_orgnaization_id")
            ?.setValidators(Validators.required);
          this.saveForm
            .get("case_sun_org_id")
            ?.setValidators(Validators.required);
          this.saveForm.get("case_se_id")?.setValidators(Validators.required);
          this.saveForm.get("case_ee_id")?.clearValidators();
          this.saveForm?.updateValueAndValidity();
        } else {
          this.saveForm.get("case_orgnaization_id")?.clearValidators();
          this.saveForm.get("case_sun_org_id")?.clearValidators();
          this.saveForm.get("case_se_id")?.clearValidators();
          this.saveForm.get("case_ee_id")?.clearValidators();
          this.saveForm?.updateValueAndValidity();
        }
      }
    });
  }
}
