import { AfterViewInit, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';
import { Subject } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-year-wise-report",
  templateUrl: "./year-wise-report.component.html",
  styleUrls: ["./year-wise-report.component.css"],
})
export class YearWiseReportComponent implements OnInit, AfterViewInit {
  getData: any;
  title = "datatables";
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild("noteModal") noteModal: any;
  year: any = [];
  getDataO: any;
  userID: any
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  constructor(private CaseS: CaseService,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService) {
    this.userID = localStorage.getItem("id");
  }
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    var dateToday = new Date();
    var i;
    for (i = 0; i < 51; i++) {
      var y = dateToday.getFullYear() - i;
      this.year.push(y);
    }


    this.spinner.show();
    // GetCaseData
    this.CaseS.GetCaseDatas(this.userID).subscribe((data: any) => {
      this.getData = data.resultData;
      // console.log(this.getData, " : Data")
      this.dtOptions = {
        pagingType: 'full_numbers',
        ordering: false,
        searching: true,
        processing: true,
        dom: 'Blfrtip',
        "info": true,
        "lengthMenu": [[10, 100, 500, -1], [10, 100, 500, "All"]],
      };
      let _self = this;
      setTimeout(function () {
        _self.dtTrigger.next();
        _self.spinner.hide();
      }.bind(this));

    });
    this.orgData();

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    $.fn.dataTable.ext.search.pop();
    // this.events.destroy('reports');
  }

  getDataYear(d: any) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (d.target.value != "") {
        dtInstance
          .column(1)
          .search("^" + d.target.value + "$", true, false, true)
          .draw();
      } else {
        window.location.reload();
      }
    });
  }

  orgData() {
    this.MasterS.GetOrgdata().subscribe((data: any) => {

      this.getDataO = data.resultData;
    });
  }
  viewdata() {
    this.CaseS.GetCaseData(this.userID).subscribe((data: any) => {

      this.getData = data.resultData;
    });
  }

  refresh(): void {
    window.location.reload();
  }
}
