import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { Beneficiary } from '../case-details/case-details.component';



@Component({
  selector: 'app-case-timeline',
  templateUrl: './case-timeline.component.html',
  styleUrls: ['./case-timeline.component.css']
})
export class CaseTimelineComponent implements OnInit {
  myControl = new FormControl('');
  options: string[] = [];


  filteredOptions: Observable<Beneficiary[]> | undefined;


  isShow = true;
  casedata: any;
  getCaseNoData: any;
  caseid: any;
  sourceValue: any;
  showData: boolean = true;
  saveFormDoc: any;
  username: any;
  router: any;
  Udata: any;
  id: any;
  ids: any;
  beneficiarylist: Beneficiary[] = [];




  constructor(private MasterS: MasterService,
    private _Activatedroute: ActivatedRoute,
  ) {
    this.caseid = this._Activatedroute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.username = localStorage.getItem('username')
      ? localStorage.getItem('username')
      : this.router.navigate(['/']);
    this.Udata = localStorage.getItem('userdata');

    this.MasterS
      .getDataCaseNoById(this.caseid)
      .subscribe((data: any) => {


        this.casedata = data.resultData;
        this.caseid = this.casedata[0]?._id
      });

    this.getCaseNolist();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(beneficiarylist => (beneficiarylist ? this._filter(beneficiarylist) : this.beneficiarylist.slice())),

    );

  }


  private _filter(value: string): Beneficiary[] {
    const filterValue = value.toLowerCase();
    return this.getCaseNoData.filter((option: any) => option.case_no.toLowerCase().includes(filterValue))
  }


  getCaseNolist() {
    this.MasterS.GetCaseNo().subscribe((data: any) => {
      this.getCaseNoData = data.resultData;

    });
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  getCaseNo(e: any) {
    let case_no = e;
    // console.log(e)
    this.sourceValue = case_no;

    if (this.sourceValue === 'VALUEA') {
      this.showData = true;
    }
    else {
      this.showData = false;
    }

    this.MasterS
      .getDataCaseNoById({
        case_no: e,

      })
      .subscribe((data: any) => {
        this.casedata = data;


        this.caseid = this.casedata?.resultData[0]?._id

      });[5]
  }




}
