import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.css']
})
export class PrintReportComponent implements OnInit {
  all_selected_dep: any
  court_id: any;

  getDataO: any[] = [];
  getDataS: any[] = [];
  uniqueOrg: any[] = [];
  subOrgDataByOrg: any[] = [];
  getDataC: any[] = [];
  makayog_data: any = {};
  ids: any;
  holdedData: any[] = [];

  court_name: string = 'मा0 उच्च न्यायालय लखनऊ';

  mkul = 0;
  mdakhil = 0;
  msesh = 0;
  mnot_required = 0;
  manu_1 = 0;
  manu_2 = 0;
  manu_3 = 0;
  manu_4 = 0;
  manu_5 = 0;
  manu_6 = 0;
  manu_7 = 0;
  manu_8 = 0;
  manu_9 = 0;
  manu_10 = 0;
  manu_11 = 0;
  manu_12 = 0;
  manu_13 = 0;
  mlagu_nhi = 0;
  mparti = 0;


  mses_1 = 0;
  mses_2 = 0;
  mses_3 = 0;
  mses_4 = 0;
  mses_5 = 0;
  mses_6 = 0;
  mses_7 = 0;
  mses_8 = 0;
  mses_9 = 0;
  mses_10 = 0;
  mses_11 = 0;
  mses_12 = 0;
  mses_13 = 0;

  report_type: boolean = true;
  constructor(private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private httpService: CaseService,) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params['arrayParam']) {
        this.all_selected_dep = JSON.parse(params['arrayParam']);
      }
      if (params['court_id']) {
        this.court_id = params['court_id'];


      }
      if (params['court_name']) {
        this.court_name = params['court_name'];
      }
      if (params['type']) {
        // console.log("TYPE: ", params['type'])
        this.report_type = params['type'] == 'a' ? true : false;
      }
      if (this.all_selected_dep?.length > 0 && this.court_id) {
        if (this.report_type == true) {
          this.getAOne();
        } else {
          this.getBOne();
        }

      }
    });
  }

  ngOnInit(): void {

  }



  getAOne() {
    this.spinner.show();
    this.httpService.getAOne({ department_id: this.all_selected_dep, case_court_id: this.court_id }).subscribe({
      next: (resp: any) => {

        this.uniqueOrg = [];
        this.uniqueOrg = resp['data'];
        this.holdedData = [];
        resp['data'].filter((caseObj: any) => {
          // for (let data of caseObj) {
          let body: any = {};

          caseObj['kul'] = caseObj.reduce((acc: any, data: any) => acc + data.kul_wad, 0);
          body['kul'] = caseObj['kul'];
          caseObj['dakhil'] = caseObj.reduce((acc: any, data: any) => acc + data.dakhil, 0);
          body['dakhil'] = caseObj['dakhil'];
          caseObj['sesh'] = caseObj.reduce((acc: any, data: any) => acc + data.sesh, 0);
          body['sesh'] = caseObj['sesh'];

          caseObj['not_required'] = caseObj.reduce((acc: any, data: any) => acc + data.not_required, 0);
          body['not_required'] = caseObj['not_required'];

          caseObj['kul_wad'] = Number(caseObj?.anu_1) + Number(caseObj?.anu_2) + Number(caseObj?.anu_3) + Number(caseObj?.anu_4) + Number(caseObj?.anu_5) + Number(caseObj?.anu_6) + Number(caseObj?.anu_7) + Number(caseObj?.anu_8) + Number(caseObj?.anu_9) + Number(caseObj?.anu_10) + Number(caseObj?.anu_11) + Number(caseObj?.anu_12) + Number(caseObj?.anu_13);
          body['kul_wad'] = caseObj['kul_wad'];
          caseObj['ses_1'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_1, 0);
          body['ses_1'] = caseObj['ses_1'];
          caseObj['ses_2'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_2, 0);
          body['ses_2'] = caseObj['ses_2'];
          caseObj['ses_3'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_3, 0);
          body['ses_3'] = caseObj['ses_3'];
          caseObj['ses_4'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_4, 0);
          body['ses_4'] = caseObj['ses_4'];
          caseObj['ses_5'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_5, 0);
          body['ses_5'] = caseObj['ses_5'];
          caseObj['ses_6'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_6, 0);
          body['ses_6'] = caseObj['ses_6'];
          caseObj['ses_7'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_7, 0);
          body['ses_7'] = caseObj['ses_7'];
          caseObj['ses_8'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_8, 0);
          body['ses_8'] = caseObj['ses_8'];
          caseObj['ses_9'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_9, 0);
          body['ses_9'] = caseObj['ses_9'];
          caseObj['ses_10'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_10, 0);
          body['ses_10'] = caseObj['ses_10'];
          caseObj['ses_11'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_11, 0);
          body['ses_11'] = caseObj['ses_11'];
          caseObj['ses_12'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_12, 0);
          body['ses_12'] = caseObj['ses_12'];
          caseObj['ses_13'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_13, 0);
          body['ses_13'] = caseObj['ses_13'];
          caseObj['anu_1'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_1, 0);
          body['anu_1'] = caseObj['anu_1'];
          caseObj['anu_2'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_2, 0);
          body['anu_2'] = caseObj['anu_2'];
          caseObj['anu_3'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_3, 0);
          body['anu_3'] = caseObj['anu_3'];
          caseObj['anu_4'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_4, 0);
          body['anu_4'] = caseObj['anu_4'];
          caseObj['anu_5'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_5, 0);
          body['anu_5'] = caseObj['anu_5'];
          caseObj['anu_6'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_6, 0);
          body['anu_6'] = caseObj['anu_6'];
          caseObj['anu_7'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_7, 0);
          body['anu_7'] = caseObj['anu_7'];
          caseObj['anu_8'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_8, 0);
          body['anu_8'] = caseObj['anu_8'];
          caseObj['anu_9'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_9, 0);
          body['anu_9'] = caseObj['anu_9'];
          caseObj['anu_10'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_10, 0);
          body['anu_10'] = caseObj['anu_10'];
          caseObj['anu_11'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_11, 0);
          body['anu_11'] = caseObj['anu_11'];
          caseObj['anu_12'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_12, 0);
          body['anu_12'] = caseObj['anu_12'];
          caseObj['anu_13'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_13, 0);
          body['anu_13'] = caseObj['anu_13'];
          // }


          this.holdedData.push(body);
          return caseObj
        });


        this.mkul = this.holdedData.reduce((acc: any, data: any) => acc + data.kul, 0);

        this.mdakhil = this.holdedData.reduce((acc: any, data: any) => acc + data.dakhil, 0);

        this.msesh = this.holdedData.reduce((acc: any, data: any) => acc + data.sesh, 0);


        this.mnot_required = this.holdedData.reduce((acc: any, data: any) => acc + data.not_required, 0);


        // caseObj['kul_wad'] = Number(this.holdedData.anu_1) + Number(caseObj?.anu_2) + Number(caseObj?.anu_3) + Number(caseObj?.anu_4) + Number(caseObj?.anu_5) + Number(caseObj?.anu_6) + Number(caseObj?.anu_7) + Number(caseObj?.anu_8) + Number(caseObj?.anu_9) + Number(caseObj?.anu_10) + Number(caseObj?.anu_11) + Number(caseObj?.anu_12) + Number(caseObj?.anu_13);

        this.mses_1 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_1, 0);

        this.mses_2 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_2, 0);

        this.mses_3 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_3, 0);

        this.mses_4 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_4, 0);

        this.mses_5 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_5, 0);

        this.mses_6 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_6, 0);

        this.mses_7 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_7, 0);

        this.mses_8 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_8, 0);

        this.mses_9 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_9, 0);

        this.mses_10 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_10, 0);

        this.mses_11 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_11, 0);

        this.mses_12 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_12, 0);

        this.mses_13 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_13, 0);

        this.manu_1 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_1, 0);

        this.manu_2 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_2, 0);

        this.manu_3 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_3, 0);

        this.manu_4 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_4, 0);

        this.manu_5 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_5, 0);

        this.manu_6 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_6, 0);

        this.manu_7 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_7, 0);

        this.manu_8 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_8, 0);

        this.manu_9 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_9, 0);

        this.manu_10 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_10, 0);

        this.manu_11 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_11, 0);

        this.manu_12 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_12, 0);

        this.manu_13 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_13, 0);


        resp['data'].filter((caseObj: any) => {

          for (let data of caseObj) {
            caseObj['org_name'] = (data.org_name || '');
          }
          // caseObj['org_name'] = (caseObj.org_name || 0);
          return caseObj
        });




        // this.lagu_nhi = resp['data'].reduce((acc: any, data: any) => acc + data.lagu_nhi, 0);
        // this.parti = resp['data'].reduce((acc: any, data: any) => acc + data.parti, 0);
        this.spinner.hide();
      },
      error: (err: any) => {

        this.spinner.hide();
      }
    });
  }

  getBOne() {
    this.spinner.show();
    this.httpService.getBOne({ department_id: this.all_selected_dep, case_court_id: this.court_id }).subscribe({
      next: (resp: any) => {

        this.uniqueOrg = [];
        this.uniqueOrg = resp['data'];
        this.holdedData = [];
        resp['data'].filter((caseObj: any) => {
          // for (let data of caseObj) {
          let body: any = {};

          caseObj['kul'] = caseObj.reduce((acc: any, data: any) => acc + data.kul_wad, 0);
          body['kul'] = caseObj['kul'];
          caseObj['dakhil'] = caseObj.reduce((acc: any, data: any) => acc + data.dakhil, 0);
          body['dakhil'] = caseObj['dakhil'];
          caseObj['sesh'] = caseObj.reduce((acc: any, data: any) => acc + data.sesh, 0);
          body['sesh'] = caseObj['sesh'];

          caseObj['not_required'] = caseObj.reduce((acc: any, data: any) => acc + data.not_required, 0);
          body['not_required'] = caseObj['not_required'];

          caseObj['kul_wad'] = Number(caseObj?.anu_1) + Number(caseObj?.anu_2) + Number(caseObj?.anu_3) + Number(caseObj?.anu_4) + Number(caseObj?.anu_5) + Number(caseObj?.anu_6) + Number(caseObj?.anu_7) + Number(caseObj?.anu_8) + Number(caseObj?.anu_9) + Number(caseObj?.anu_10) + Number(caseObj?.anu_11) + Number(caseObj?.anu_12) + Number(caseObj?.anu_13);
          body['kul_wad'] = caseObj['kul_wad'];
          caseObj['ses_1'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_1, 0);
          body['ses_1'] = caseObj['ses_1'];
          caseObj['ses_2'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_2, 0);
          body['ses_2'] = caseObj['ses_2'];
          caseObj['ses_3'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_3, 0);
          body['ses_3'] = caseObj['ses_3'];
          caseObj['ses_4'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_4, 0);
          body['ses_4'] = caseObj['ses_4'];
          caseObj['ses_5'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_5, 0);
          body['ses_5'] = caseObj['ses_5'];
          caseObj['ses_6'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_6, 0);
          body['ses_6'] = caseObj['ses_6'];
          caseObj['ses_7'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_7, 0);
          body['ses_7'] = caseObj['ses_7'];
          caseObj['ses_8'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_8, 0);
          body['ses_8'] = caseObj['ses_8'];
          caseObj['ses_9'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_9, 0);
          body['ses_9'] = caseObj['ses_9'];
          caseObj['ses_10'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_10, 0);
          body['ses_10'] = caseObj['ses_10'];
          caseObj['ses_11'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_11, 0);
          body['ses_11'] = caseObj['ses_11'];
          caseObj['ses_12'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_12, 0);
          body['ses_12'] = caseObj['ses_12'];
          caseObj['ses_13'] = caseObj.reduce((acc: any, data: any) => acc + data.ses_13, 0);
          body['ses_13'] = caseObj['ses_13'];
          caseObj['anu_1'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_1, 0);
          body['anu_1'] = caseObj['anu_1'];
          caseObj['anu_2'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_2, 0);
          body['anu_2'] = caseObj['anu_2'];
          caseObj['anu_3'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_3, 0);
          body['anu_3'] = caseObj['anu_3'];
          caseObj['anu_4'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_4, 0);
          body['anu_4'] = caseObj['anu_4'];
          caseObj['anu_5'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_5, 0);
          body['anu_5'] = caseObj['anu_5'];
          caseObj['anu_6'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_6, 0);
          body['anu_6'] = caseObj['anu_6'];
          caseObj['anu_7'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_7, 0);
          body['anu_7'] = caseObj['anu_7'];
          caseObj['anu_8'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_8, 0);
          body['anu_8'] = caseObj['anu_8'];
          caseObj['anu_9'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_9, 0);
          body['anu_9'] = caseObj['anu_9'];
          caseObj['anu_10'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_10, 0);
          body['anu_10'] = caseObj['anu_10'];
          caseObj['anu_11'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_11, 0);
          body['anu_11'] = caseObj['anu_11'];
          caseObj['anu_12'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_12, 0);
          body['anu_12'] = caseObj['anu_12'];
          caseObj['anu_13'] = caseObj.reduce((acc: any, data: any) => acc + data.anu_13, 0);
          body['anu_13'] = caseObj['anu_13'];
          // }


          this.holdedData.push(body);
          return caseObj
        });


        this.mkul = this.holdedData.reduce((acc: any, data: any) => acc + data.kul, 0);

        this.mdakhil = this.holdedData.reduce((acc: any, data: any) => acc + data.dakhil, 0);

        this.msesh = this.holdedData.reduce((acc: any, data: any) => acc + data.sesh, 0);


        this.mnot_required = this.holdedData.reduce((acc: any, data: any) => acc + data.not_required, 0);


        // caseObj['kul_wad'] = Number(this.holdedData.anu_1) + Number(caseObj?.anu_2) + Number(caseObj?.anu_3) + Number(caseObj?.anu_4) + Number(caseObj?.anu_5) + Number(caseObj?.anu_6) + Number(caseObj?.anu_7) + Number(caseObj?.anu_8) + Number(caseObj?.anu_9) + Number(caseObj?.anu_10) + Number(caseObj?.anu_11) + Number(caseObj?.anu_12) + Number(caseObj?.anu_13);

        this.mses_1 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_1, 0);

        this.mses_2 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_2, 0);

        this.mses_3 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_3, 0);

        this.mses_4 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_4, 0);

        this.mses_5 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_5, 0);

        this.mses_6 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_6, 0);

        this.mses_7 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_7, 0);

        this.mses_8 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_8, 0);

        this.mses_9 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_9, 0);

        this.mses_10 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_10, 0);

        this.mses_11 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_11, 0);

        this.mses_12 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_12, 0);

        this.mses_13 = this.holdedData.reduce((acc: any, data: any) => acc + data.ses_13, 0);

        this.manu_1 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_1, 0);

        this.manu_2 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_2, 0);

        this.manu_3 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_3, 0);

        this.manu_4 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_4, 0);

        this.manu_5 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_5, 0);

        this.manu_6 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_6, 0);

        this.manu_7 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_7, 0);

        this.manu_8 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_8, 0);

        this.manu_9 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_9, 0);

        this.manu_10 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_10, 0);

        this.manu_11 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_11, 0);

        this.manu_12 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_12, 0);

        this.manu_13 = this.holdedData.reduce((acc: any, data: any) => acc + data.anu_13, 0);


        resp['data'].filter((caseObj: any) => {

          for (let data of caseObj) {
            caseObj['org_name'] = (data.org_name || '');
          }
          // caseObj['org_name'] = (caseObj.org_name || 0);
          return caseObj
        });

        this.spinner.hide();
      },
      error: (err: any) => {

        this.spinner.hide();
      }
    });
  }

}
