<div id="content" class="app-content" role="main">
    <div class="app-content-body ">
        <div class="lter b-b py-5 ps-4 bg-white box-shadow">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <h3 class="m-n font-medium">
                        <span *ngIf="!operation">Add</span><span *ngIf="operation == 'edit'">Update</span> Case
                    </h3>
                </div>
                <div class="col-sm-6 col-xs-12"></div>
            </div>
        </div>

        <div class="wrapper-md">
            <div class="panel b-a">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="head-title">
                                <h4>Case Information </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bb"></div>
                <div class="panel-body">
                    <form [formGroup]="saveFormMain" (ngSubmit)="clickFunctionMain()">
                        <div class="row" id="writ1" *ngIf="writ_statusCondition">
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Case No. / वाद संख्या <span class="text-danger">*</span></label>
                                    <input class="form-control" type="text" formControlName="case_no"
                                        placeholder="Enter Case No.">
                                    <div *ngIf="main.case_no.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_no.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Case Year / वाद वर्ष</label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_year">
                                        <option value=''>Select</option>
                                        <option *ngFor="let i of year " value={{i}}>{{i}}</option>
                                    </select>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Petitioner / वादीगण <span class="text-danger">*</span></label>
                                    <div class="input-group d-flex">
                                        <input type="text" placeholder="Select Petitioners" class="form-control no-user"
                                            data-toggle="modal" data-target="#dropDownModal"
                                            (click)="openModal('case_petitioner_id', getDataPet, 'Petitioner / वादीगण')"
                                            (keydown)="prevent()" readonly [class.disabled]="operation == 'view'"
                                            [disabled]="operation == 'view'">
                                        <button data-toggle="modal"
                                            *ngIf="roledata.petitionerCreate && operation != 'view'"
                                            data-target="#largeModal" class="btn btn-default" type="button"> + </button>
                                    </div>
                                    <div *ngIf="main.case_petitioner_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_petitioner_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Respondent / प्रतिवादीगण <span class="text-danger">*</span></label>
                                    <div class="input-group d-flex">
                                        <input type="text" placeholder="Select Respondent" class="form-control no-user"
                                            data-toggle="modal" data-target="#dropDownModal"
                                            (click)="openModal('case_respondent_id', getDataRes, 'Respondent / प्रतिवादीगण', 'res_name')"
                                            (keydown)="prevent()" readonly [class.disabled]="operation == 'view'"
                                            [disabled]="operation == 'view'">
                                        <button data-toggle="modal"
                                            *ngIf="roledata.respondentCreate && operation != 'view'"
                                            data-target="#largeModal" class="btn btn-default" type="button"> +
                                        </button>
                                    </div>

                                    <div *ngIf="main.case_respondent_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_respondent_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Department / विभाग <span class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_orgnaization_id" (change)="getSubOrgByOrgd($event)">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataO" value={{data._id}}>{{data.org_name}}
                                        </option>
                                    </select>
                                    <div *ngIf="main.case_orgnaization_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_orgnaization_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <input type="hidden" formControlName="created_by">
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Organization / CE <span class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_sun_org_id" (change)="getSeBySubOrgD($event)">
                                        <option value=''>Select</option>
                                        <option *ngFor="let dobj of subOrgDataByOrg" value={{dobj._id}}>
                                            {{dobj.sub_org_name}} </option>
                                    </select>
                                    <div *ngIf="main.case_sun_org_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_sun_org_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">SE <span class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_se_id" (change)="getEeBySeD($event)">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of seDataBySubOrg" value={{data._id}}>{{data.se_name}}
                                        </option>
                                    </select>
                                    <div *ngIf="main.case_se_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_se_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">EE <span class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_ee_id" (change)="getEeByIdData($event)">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of EeDataBySe" value={{data._id}}>{{data.ee_name}}
                                        </option>
                                    </select>
                                    <div *ngIf="main.case_ee_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_ee_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <table class="table table-borderless w-100">
                                    <tr>
                                        <td>Contempt / अवमानना</td>
                                        <td>
                                            <label class="radio-inline">
                                                <input (click)="isContempts(true)" type="radio"
                                                    formControlName="case_contempt" value="Yes">Yes
                                            </label>
                                            <label class="radio-inline">
                                                <input (click)="isContempts(false)" type="radio"
                                                    formControlName="case_contempt" value="No">No
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>SLP / अनुमति याचिका</td>
                                        <td>
                                            <label class="radio-inline">
                                                <input type="radio" value="Yes" id="slpyes" value="Yes"
                                                    formControlName="case_slp">Yes
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" value="No" id="slpno" value="No"
                                                    formControlName="case_slp" [attr.checked]="true">No
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>PIL</td>
                                        <td>
                                            <label class="radio-inline">
                                                <input type="radio" formControlName="PIL" value="Yes">Yes
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" formControlName="PIL" value="No">No
                                            </label>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <div class="col-md-6">
                                <table class="table table-borderless w-100">
                                    <tr>
                                        <td>HOD As a Party</td>
                                        <td>
                                            <label class="radio-inline">
                                                <input type="radio" value="Yes" value="Yes" formControlName="hod">Yes
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" value="No" value="No" formControlName="hod"
                                                    [attr.checked]="true">No
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>PS As a Party</td>
                                        <td>
                                            <label class="radio-inline">
                                                <input type="radio" value="Yes" value="Yes" formControlName="PSR">Yes
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" value="No" value="No" formControlName="PSR"
                                                    [attr.checked]="true">No
                                            </label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>CS As a Party</td>
                                        <td>
                                            <label class="radio-inline">
                                                <input type="radio" value="Yes" value="Yes" formControlName="CSR">Yes
                                            </label>
                                            <label class="radio-inline">
                                                <input type="radio" value="No" value="No" formControlName="CSR"
                                                    [attr.checked]="true">No
                                            </label>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div class="row" *ngIf="isContempt">
                            <div class="col-md-3" *ngIf="!caseid">
                                <div class="input_grp form-group">
                                    <label for="">Date of Personal Appearence </label>
                                    <input class="form-control" type="date">
                                </div>
                            </div>

                            <div class="col-md-3" *ngIf="!caseid">
                                <div class="input_grp form-group">
                                    <label for="">Date of Compliance </label>
                                    <input class="form-control" type="date">
                                </div>
                            </div>

                            <div class="col-md-6" [class.col-md-3]="!caseid">
                                <div class="input_grp form-group">
                                    <label for="">Court / न्यायालय <span class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_court_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataC" value={{data._id}}>{{data.court_name}}
                                        </option>

                                    </select>
                                    <div *ngIf="main.case_court_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_court_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6" [class.col-md-3]="!caseid">
                                <div class="input_grp form-group">
                                    <label for="">Contempt Case No. / अवमाननावाद संख्या</label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_contempt_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getCaseNo" value={{data.case_no}}>
                                            {{data.case_no}}
                                        </option>
                                    </select>

                                </div>
                            </div>
                        </div>

                        <div class="row" id="sub4"
                            [ngStyle]="{display: main.case_contempt.value == 'Yes' ? 'flex' : 'none'}">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Reason for Non-Compliance / पारित आदेश का विवरण जिसका अनुपालन ना करने
                                        पर अवमाननावाद दायर किया गया</label>
                                    <textarea formControlName="reason_non_compliance" id="" rows="2"
                                        placeholder="Enter.." class="w-100 textarea"></textarea>
                                </div>
                            </div>
                        </div>
                        <a data-toggle="modal" data-target="#largeModal00" id="sub3" href="javascript:void(0)"
                            style="color:blue; text-decoration: underline;"
                            [ngStyle]="{display: main.case_contempt.value == 'Yes' ? 'inline' : 'none'}"></a>
                        <div class="bb form-group"></div>

                        <div class="row">
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Court / न्यायालय <span class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_court_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataC" value={{data._id}}>{{data.court_name}}
                                        </option>

                                    </select>
                                    <div *ngIf="main.case_court_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_court_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">District / जिला <span class="text-danger">*</span></label>
                                    <select style="text-transform: uppercase;"
                                        class="form-control select-language form-control-sm validation"
                                        formControlName="case_district_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataDist" style="text-transform: uppercase;"
                                            value={{data._id}}>{{data.district}}
                                        </option>
                                    </select>
                                    <div *ngIf="main.case_district_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_district_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Govt. Section / शासन अनुभाग <span class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_section_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataS" value={{data._id}}>{{data.section_name}}
                                        </option>
                                    </select>
                                    <div *ngIf="main.case_section_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_section_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">H.O. Section / Cell <span class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_ho_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataH" value={{data._id}}>{{data.ho_name}}
                                        </option>
                                    </select>
                                    <div *ngIf="main.case_ho_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_ho_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bb form-group"></div>
                        <div class="row">
                            <div class="col-sm-6 col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Writ Notice No. / रिट सूचना संख्या</label>
                                    <input class="form-control" placeholder="Enter Notice No." type="text"
                                        formControlName="case_notice_no">
                                </div>
                            </div>

                            <!-- <div class="col-sm-6 col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Writ Status <span class="text-danger">*</span></label>
                                    <div class="">
                                        <label class="radio-inline">
                                            <input (click)="writ_Status('inline')" type="radio"
                                                formControlName="writ_status" value="Accepted" checked>Accepted
                                        </label>
                                        <label class="radio-inline">
                                            <input (click)="writ_Status('none')" type="radio"
                                                formControlName="writ_status" value="Not Accepted">Not Accepted
                                        </label>

                                    </div>
                                </div>
                            </div> -->

                            <div class="col-sm-6 col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Filing Date / दाखिल तिथि </label>
                                    <input class="form-control" type="date" formControlName="case_filing_date">
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Case Subject / वाद विषय <span class="text-danger">*</span></label>

                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_subject">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDatacaseSub" value={{data._id}}>
                                            {{data.case_subject_name}}</option>
                                    </select>
                                    <div *ngIf="main.case_subject.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_subject.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Case Classification / वाद वर्गीकरण <span
                                            class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_classification_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataCl" value={{data._id}}>
                                            {{data.case_classi_name}}</option>
                                    </select>
                                    <div *ngIf="main.case_classification_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_classification_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Case Status / वाद अद्यतन स्थिति <span
                                            class="text-danger">*</span></label>
                                    <select class="form-control select-language form-control-sm validation"
                                        formControlName="case_status_id">
                                        <option value=''>Select</option>
                                        <option *ngFor="let data of getDataCS" value={{data._id}}>
                                            {{data.case_status_name}}</option>
                                    </select>
                                    <div *ngIf="main.case_status_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_status_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Ref. Case No. / संदर्भ वाद संख्या</label>
                                    <select class="form-control select-language form-control-sm     validation"
                                        formControlName="ref_case_no" (click)="getCaseNoByRefNo($event)">
                                        <option value="">Select</option>
                                        <option *ngFor="let data of getCaseNo" value={{data.case_no}}>{{data.case_no}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf='this.saveFormMain.value?.case_status_id== "643cd398f9945d7d0aed25a8"'
                                class="col-sm-12 col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Next Date /अगली तारीख</label>
                                    <input class="form-control" type="date" formControlName="nextDate">
                                </div>
                            </div>
                            <div *ngIf='this.saveFormMain.value?.case_status_id== "643cd398f9945d7d0aed25a8"'
                                class="col-sm-12 col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Previous Date / पिछली तारीख</label>
                                    <input class="form-control" type="date" formControlName="previousDate">
                                </div>
                            </div>
                            <div *ngIf="saveFormMain.value?.case_status_id==='643cd3a1f9945d7d0aed25ab'"
                                class="col-sm-12 col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Disposed Date / निस्तारित तिथि</label>
                                    <input class="form-control" type="date" formControlName="disposedDate">
                                </div>
                            </div>
                            <div *ngIf="saveFormMain.value?.case_status_id==='643cd3a1f9945d7d0aed25ab'"
                                class="col-sm-12 col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Due Date For compliance /अनुपालन के लिए नियत तिथि</label>
                                    <input class="form-control" type="date" formControlName="complianceDate">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Case Prayer / वाद प्रार्थना <span class="text-danger">*</span></label>
                                    <textarea class="form-control" type="text" formControlName="case_prayer"
                                        placeholder="Enter Case Prayer"></textarea>
                                </div>
                                <div *ngIf="main.case_prayer.errors && isValidFormSubmittedMain"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="main.case_prayer.errors">This field is required</div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">Case Description / प्रकरण संक्षिप्त विवरण <span
                                            class="text-danger">*</span></label>
                                    <textarea class="form-control" type="text" formControlName="case_description"
                                        placeholder="Enter Case Description"></textarea>
                                </div>
                                <div *ngIf="main.case_description.errors && isValidFormSubmittedMain"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="main.case_description.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="bb form-group"></div>

                        <div class="row">
                            <div class="col-md-3">
                                <div class="input_grp form-group">
                                    <label for="">Affidavit Filed / प्रतिशपथ पत्र दाख़िला </label>
                                    <div class="">
                                        <label class="radio-inline">
                                            <input type="radio" formControlName="case_affidavit_field" value="Yes"
                                                (click)="reason_filingvisibility(false)">Yes
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" formControlName="case_affidavit_field" value="No"
                                                (click)="reason_filingvisibility(true)">No
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" formControlName="case_affidavit_field"
                                                value="Not Required" (click)="reason_filingvisibility(true)">Not
                                            Required
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-4" id="sub5"
                                [ngStyle]="{display: main.case_affidavit_field.value == 'Yes' ? 'inline' : 'none'}">
                                <div class="input_grp form-group">
                                    <label for="">Affidavit Filing Date / प्रतिशपथ पत्र दाख़िल कराने की तिथि</label>
                                    <input class="form-control" type="date"
                                        formControlName="case_affidavit_filing_date">
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="input_grp form-group">
                                    <label for="">Affidavit Filing Due Date / प्रतिशपथ पत्र दाख़िल कराने की निर्धारित
                                        तिथि</label>
                                    <input class="form-control" type="date" formControlName="case_affidavit_due_date">
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="reason_filing" id="reason_filing">
                                <div class="input_grp form-group">
                                    <label for="">Reason For Not Filing Affidavit / प्रतिशपथ पत्र दाख़िल न कराने का
                                        कारण</label>
                                    <textarea class="form-control" type="text" formControlName="reason_affidavit_filing"
                                        placeholder="Enter Reason For Not Filing Affidavit"></textarea>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-2">
                                <div class="input_grp form-group">
                                    <label for="">Rejoinder </label>
                                    <div class="">
                                        <label class="radio-inline">
                                            <input type="radio" formControlName="case_rejoinder" value="Yes">Yes
                                        </label>
                                        <label class="radio-inline">
                                            <input type="radio" formControlName="case_rejoinder" value="No" checked>No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="input_grp form-group" id="sub7"
                                    [ngStyle]="{display: main.case_rejoinder.value == 'Yes' ? 'inline' : 'none'}">
                                    <label for="">Rejoinder Date</label>
                                    <input class="form-control" type="date" formControlName="case_rejoinder_date">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input_grp form-group">
                                    <label for="">File No.</label>
                                    <input class="form-control" type="text" formControlName="case_file_no"
                                        placeholder="Enter File No.">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Remark / अभ्युक्ति</label>
                                    <textarea class="form-control" type="text" formControlName="case_remark"
                                        placeholder="Enter Remark"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="bb form-group"></div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Counsel / अधिवक्ता <span class="text-danger">*</span></label>
                                    <div class="input-group d-flex">
                                        <input type="text" placeholder="Select Counsel" class="form-control no-user"
                                            data-toggle="modal" data-target="#dropDownModal"
                                            (click)="openModal('case_counsel_id', getDataCounsel, 'Counsel / अधिवक्ता', 'coun_name')"
                                            (keydown)="prevent()" readonly [class.disabled]="operation == 'view'"
                                            [disabled]="operation == 'view'">
                                        <button data-toggle="modal"
                                            *ngIf="roledata.counselCreate && operation != 'view'"
                                            data-target="#largeModal" class="btn btn-default" type="button"> +
                                        </button>
                                    </div>
                                    <div *ngIf="main.case_counsel_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_counsel_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Counsel / अधिवक्ता</label>
                                    <div class="input-group">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:6%;">S. No.</th>
                                                    <th>Counsel Name</th>
                                                    <th>Mobile</th>
                                                    <th>Email</th>
                                                    <th style="width:10%;text-align:center;"
                                                        *ngIf="operation != 'view'">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of councelById; let i= index;">
                                                    <td class="text-center">{{ [i+1] }}</td>
                                                    <td>{{data.coun_name}}</td>
                                                    <td>{{data.coun_mobile}}</td>
                                                    <td>{{data.coun_email}}</td>
                                                    <td style="text-align:center;" *ngIf="operation != 'view'">
                                                        <div class="btn-group dropdown"><button
                                                                class="btn btn-default w-xxs btn-sm r-2x"
                                                                data-toggle="dropdown" aria-expanded="false"><i
                                                                    class="fa fa-cog g-px-5  text-info text-sm"></i>
                                                                <span class=""><i
                                                                        class="fa fa-caret-down"></i></span></button>
                                                            <ul class="dropdown-menu">
                                                                <li><a routerLink="/dashboard/councel-details/{{data._id}}"
                                                                        routerLinkActive="active" target="_blank"><i
                                                                            class="fa fa-eye" aria-hidden="true"></i>
                                                                        View </a>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Ref. Case No. / संदर्भ वाद संख्या</label>
                                    <div class="input-group">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:6%;">S. No.</th>
                                                    <th>Ref. Case No.</th>
                                                    <th>Case Year</th>
                                                    <th>Court</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of refcasedata; let i= index;">
                                                    <td class="text-center">{{ [i+1] }}</td>
                                                    <td>{{data?.case_no}}</td>
                                                    <td>{{data?.case_year}}</td>
                                                    <td>{{data?.case_court_id?.court_name}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bb form-group"></div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Petitioner / वादीगण</label>
                                    <div class="input-group d-flex">
                                        <input type="text" placeholder="Select Petitioner" class="form-control no-user"
                                            data-toggle="modal" data-target="#dropDownModal"
                                            (click)="openModal('case_petitioner_id', getDataPet, 'Petitioner / वादीगण', 'name')"
                                            (keydown)="prevent()" readonly [class.disabled]="operation == 'view'"
                                            [disabled]="operation == 'view'">
                                        <button data-toggle="modal"
                                            *ngIf="roledata.petitionerCreate && operation != 'view'"
                                            data-target="#largeModal" class="btn btn-default" type="button"> +
                                        </button>
                                    </div>
                                    <div *ngIf="main.case_petitioner_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_petitioner_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Petitioner / वादीगण</label>
                                    <div class="input-group">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:6%;">S. No.</th>
                                                    <th>Petitioner Name</th>
                                                    <th>Designation</th>
                                                    <th>Mobile</th>
                                                    <th>District</th>
                                                    <th style="width:10%;text-align:center;"
                                                        *ngIf="operation != 'view'">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of petiById; let i= index;">
                                                    <td class="text-center">{{ [i+1] }}</td>
                                                    <td>{{data?.name}}</td>
                                                    <td>{{data?.designation}}</td>
                                                    <td>{{data?.mobile}}</td>
                                                    <td>{{data?.district_id?.district}}</td>
                                                    <td style="text-align:center;" *ngIf="operation != 'view'">
                                                        <div class="btn-group dropdown"><button
                                                                class="btn btn-default w-xxs btn-sm r-2x"
                                                                data-toggle="dropdown" aria-expanded="false"><i
                                                                    class="fa fa-cog g-px-5  text-info text-sm"></i>
                                                                <span class=""><i
                                                                        class="fa fa-caret-down"></i></span></button>
                                                            <ul class="dropdown-menu">
                                                                <li><a routerLink="/dashboard/petitioner-details/{{data?._id}}"
                                                                        routerLinkActive="active" target="_blank"><i
                                                                            class="fa fa-eye" aria-hidden="true"></i>
                                                                        View </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bb form-group"></div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Respondent / प्रतिवादीगण</label>
                                    <div class="input-group d-flex">
                                        <input type="text" placeholder="Select Respondent" class="form-control no-user"
                                            data-toggle="modal" data-target="#dropDownModal"
                                            (click)="openModal('case_respondent_id', getDataRes, 'Petitioner / वादीगण', 'res_name')"
                                            (keydown)="prevent()" readonly [class.disabled]="operation == 'view'"
                                            [disabled]="operation == 'view'">
                                        <button data-toggle="modal"
                                            *ngIf="roledata.respondentCreate && operation != 'view'"
                                            data-target="#largeModal" class="btn btn-default" type="button"> +
                                        </button>
                                    </div>
                                    <div *ngIf="main.case_respondent_id.errors && isValidFormSubmittedMain"
                                        class="text-danger text-left mb-1">
                                        <div *ngIf="main.case_respondent_id.errors">This field is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Respondent / प्रतिवादीगण</label>
                                    <div class="input-group">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:6%;">S. No.</th>
                                                    <th>Respondent Name</th>
                                                    <th>Designation</th>
                                                    <th>Mobile</th>
                                                    <th>District</th>
                                                    <th style="width:10%;text-align:center;"
                                                        *ngIf="operation != 'view'">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of resById; let i= index;">
                                                    <td class="text-center">{{ [i+1] }}</td>
                                                    <td>{{data?.res_name}}</td>
                                                    <td>{{data?.res_designation}}</td>
                                                    <td>{{data?.res_mobile}}</td>
                                                    <td>{{data?.res_district_id.district}}</td>
                                                    <td style="text-align:center;" *ngIf="operation != 'view'">
                                                        <div class="btn-group dropdown"><button
                                                                class="btn btn-default w-xxs btn-sm r-2x"
                                                                data-toggle="dropdown" aria-expanded="false"><i
                                                                    class="fa fa-cog g-px-5  text-info text-sm"></i>
                                                                <span class=""><i
                                                                        class="fa fa-caret-down"></i></span></button>
                                                            <ul class="dropdown-menu">
                                                                <li><a routerLink="/respondent-details/{{data?._id}}"
                                                                        routerLinkActive="active" target="_blank"><i
                                                                            class="fa fa-eye" aria-hidden="true"></i>
                                                                        View </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bb form-group"></div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Representing Officer / पैरोकार</label>
                                    <div class="">
                                        <select class="form-control select-language form-control-sm validation"
                                            formControlName="case_ee_id" (change)="getEeByIdData($event)">
                                            <option value=''>Select</option>
                                            <option *ngFor="let data of getDataEE" value={{data._id}}>
                                                {{data.ee_name}}
                                            </option>
                                        </select>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="input_grp form-group">
                                    <label for="">Representing Officer / पैरोकार</label>
                                    <div class="input-group">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style="width:6%;">S. No.</th>
                                                    <th>Representing Officer Name</th>
                                                    <th>Designation</th>
                                                    <th>Mobile</th>
                                                    <th>District</th>
                                                    <th style="width:10%;text-align:center;"
                                                        *ngIf="operation != 'view'">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of EeDataById; let i= index;">
                                                    <td class="text-center">{{ [i+1] }}</td>
                                                    <td>{{data?.ee_name}}</td>
                                                    <td>{{data?.ee_designation}}</td>
                                                    <td>{{data?.ee_mobile}}</td>
                                                    <td>{{data?.ee_district_id?.district}}</td>
                                                    <td style="text-align:center;" *ngIf="operation != 'view'">
                                                        <div class="btn-group dropdown"><button
                                                                class="btn btn-default w-xxs btn-sm r-2x"
                                                                data-toggle="dropdown" aria-expanded="false"><i
                                                                    class="fa fa-cog g-px-5  text-info text-sm"></i>
                                                                <span class=""><i
                                                                        class="fa fa-caret-down"></i></span></button>
                                                            <ul class="dropdown-menu">
                                                                <li><a routerLink="/dashboard/representing-details/{{data?._id}}"
                                                                        target="_blank"><i class="fa fa-eye"
                                                                            routerLinkActive="active"
                                                                            aria-hidden="true"></i>
                                                                        View </a>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-md-12">
                                <div class="">
                                    <div class="button_group_fotter">
                                        <button type="submit" class="btn btn-primary"
                                            *ngIf="operation !== 'view'">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="largeModal" tabindex="-1" role="dialog" aria-labelledby="largeModal" aria-hidden="true"
    #petitioner>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" id="myModalLabel">Petitioner</h3>
            </div>
            <div class="modal-body">
                <form [formGroup]="saveFormPetitioner" (ngSubmit)="clickFunction()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Petitioner Type <span class="text-danger">*</span></label>
                                <div class="">
                                    <label class="radio-inline">
                                        <input type="radio" formControlName="pet_employee" value="Employee"
                                            (click)="pet_type('Employee')">Employee
                                    </label>
                                    <label class="radio-inline">
                                        <input type="radio" formControlName="pet_employee" value="Non Employee"
                                            (click)="pet_type('Non Employee')">Non Employee
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Name / नाम <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_name">
                                <div *ngIf="f.pet_name.errors && isValidFormSubmitted"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="f.pet_name.errors">This field is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Designation / पद </label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_designation">
                                <div *ngIf="f.pet_designation.errors && isValidFormSubmitted"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="f.pet_designation.errors">This field is required</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">District / जिला <span class="text-danger">*</span></label>
                                <select class="form-control select-language form-control-sm validation"
                                    formControlName="pet_district_id">
                                    <option value=''>Select</option>
                                    <option *ngFor="let data of getDataDist" value={{data._id}}>{{data.district}}
                                    </option>

                                </select>
                                <div *ngIf="f.pet_district_id.errors && isValidFormSubmitted"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="f.pet_district_id.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Email / ईमेल </label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_email">
                                <div *ngIf="f.pet_email.errors && f.pet_email.errors.pattern && isValidFormSubmitted"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="f.pet_email.errors.pattern"><small>Email is not valid</small></div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Phone / फ़ोन </label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_mobile" minlength="10" maxlength="10">
                                <div *ngIf="f.pet_mobile.errors && f.pet_mobile.errors.pattern"
                                    class="text-danger text-left mb-1 errorcontrols">
                                    <div *ngIf="f.pet_mobile.errors.pattern">Please, Enter 10 digit Mobile Number.</div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Address / पता</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_address">
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Block / ब्लॉक</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_block">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Village / ग्राम</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_village">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Pincode / पिन कोड</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_pincode">

                                <div *ngIf="f.pet_pincode.errors && f.pet_pincode.errors.pattern"
                                    class="text-danger text-left mb-1 errorcontrols">
                                    <div *ngIf="f.pet_pincode.errors.pattern">Please, Enter 6 digit Pincode </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Division / विभाजन</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="pet_divition">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="button_group_fotter">

                                <button type="submit" class="btn btn-primary" *ngIf="operation !== 'view'">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="largeModal1" tabindex="-1" role="dialog" aria-labelledby="largeModal" aria-hidden="true"
    #respondent>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" id="myModalLabel">Respondent</h3>
            </div>
            <div class="modal-body">
                <form [formGroup]="saveFormRespondent" (ngSubmit)="clickFunctionRes()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Respondent Type <span class="text-danger">*</span></label>
                                <div class="">
                                    <label class="radio-inline">
                                        <input type="radio" formControlName="res_employee" value="Employee"
                                            (click)="respondent_type('Employee')">Employee
                                    </label>
                                    <label class="radio-inline">
                                        <input type="radio" formControlName="res_employee" value="Non Employee"
                                            (click)="respondent_type('Non Employee')">Non Employee
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Name / नाम <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_name">
                                <div *ngIf="res.res_name.errors && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_name.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Designation / पद </label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_designation">
                                <div *ngIf="res.res_designation.errors && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_designation.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">District / जिला <span class="text-danger">*</span></label>
                                <select class="form-control select-language form-control-sm validation"
                                    formControlName="res_district_id">
                                    <option value=''>Select</option>
                                    <option *ngFor="let data of getDataDist" value={{data._id}}>{{data.district}}
                                    </option>

                                </select>
                                <div *ngIf="res.res_district_id.errors && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_district_id.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Email / ईमेल </label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_email">
                                <div *ngIf="res.res_email.errors && res.res_email.errors.pattern && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_email.errors.pattern"><small>Email is not valid</small></div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Phone / फ़ोन </label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_mobile" minlength="10" maxlength="10">
                                <div *ngIf="res.res_mobile.errors && res.res_mobile.errors.pattern"
                                    class="text-danger text-left mb-1 errorcontrols">
                                    <div *ngIf="res.res_mobile.errors.pattern">Please, Enter 10 digit Mobile Number.
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Block / ब्लॉक</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_block">
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Village / ग्राम</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_village">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Address / पता</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_address">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Pincode / पिन कोड</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_pincode">

                                <div *ngIf="res.res_pincode.errors && res.res_pincode.errors.pattern"
                                    class="text-danger text-left mb-1 errorcontrols">
                                    <div *ngIf="res.res_pincode.errors.pattern">Please, Enter 6 digit Pincode </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Division / विभाजन</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_divition">
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="modal-footer">

                                <button type="submit" class="btn btn-primary" *ngIf="operation !== 'view'">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="largeModal2" tabindex="-1" role="dialog" aria-labelledby="largeModal" aria-hidden="true"
    #respondent>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" id="myModalLabel">Representing Officer</h3>
            </div>
            <div class="modal-body">
                <form [formGroup]="saveFormRespondent" (ngSubmit)="clickFunctionRes()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Name / नाम <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_name">
                                <div *ngIf="res.res_name.errors && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_name.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Designation / पद <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_designation">
                                <div *ngIf="res.res_designation.errors && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_designation.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Email / ईमेल <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_email">
                                <div *ngIf="res.res_email.errors && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_email.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Phone / फ़ोन <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_mobile" minlength="10" maxlength="10">
                                <div *ngIf="res.res_mobile.errors && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_mobile.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Block / ब्लॉक</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_block">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">District / जिला <span class="text-danger">*</span></label>
                                <select class="form-control select-language form-control-sm validation"
                                    formControlName="res_district_id">
                                    <option value=''>Select</option>
                                    <option *ngFor="let data of getDataDist" value={{data._id}}>{{data.district}}
                                    </option>

                                </select>
                                <div *ngIf="res.res_district_id.errors && isValidFormSubmittedRes"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="res.res_district_id.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Village / ग्राम</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_village">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Address / पता</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_address">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Pincode / पिन कोड</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_pincode">


                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Division / विभाजन</label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="res_divition">
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="modal-footer">

                                <button type="submit" class="btn btn-primary" *ngIf="operation !== 'view'">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="basicModal" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true"
    #counsel>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">Counsel</h4>
            </div>
            <div class="modal-body">
                <form [formGroup]="saveFormCounsel" (ngSubmit)="clickFunctionCounsel()">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input_grp form-group">
                                <label for="">Name / नाम <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="coun_name">
                                <div *ngIf="coun.coun_name.errors && isValidFormSubmittedCoun"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="coun.coun_name.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input_grp form-group">
                                <label for="">Mobile / मोबाइल <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="coun_mobile" minlength="10" maxlength="10">
                                <div *ngIf="coun.coun_mobile.errors && isValidFormSubmittedCoun"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="coun.coun_mobile.errors">This field is required</div>
                                </div>
                                <div *ngIf="coun.coun_mobile.errors && coun.coun_mobile.errors.pattern"
                                    class="text-danger text-left mb-1 ">
                                    <div *ngIf="coun.coun_mobile.errors.pattern">Please, Enter 10 digit Mobile Number.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input_grp form-group">
                                <label for="">Email / ईमेल <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter.."
                                    formControlName="coun_email">


                                <div *ngIf="coun.coun_email.errors && isValidFormSubmittedCoun"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="coun.coun_email.errors">This field is required</div>
                                </div>

                                <div *ngIf="coun.coun_email.errors && coun.coun_email.errors.pattern && isValidFormSubmittedCoun"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="coun.coun_email.errors.pattern"><small>Email is not valid</small></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="modal-footer">

                                <button type="submit" class="btn btn-primary" *ngIf="operation != 'view'">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="largeModal00" tabindex="-1" role="dialog" aria-labelledby="largeModal" aria-hidden="true"
    #noteModal>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" id="myModalLabel">Contempt Case Information</h3>
            </div>
            <div class="modal-body">
                <form [formGroup]="saveFormContempt" (ngSubmit)="clickFunctionContempt()" id="contempt1">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Contempt Case No. / अवमाननावाद संख्या <span
                                        class="text-danger">*</span></label>
                                <input class="form-control" type="text" placeholder="Enter Contempt Case No."
                                    formControlName="contempt_no">
                                <div *ngIf="cont.contempt_no.errors && isValidFormSubmittedCont"
                                    class="text-danger text-left mb-1">
                                    <div *ngIf="cont.contempt_no.errors">This field is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Contempt Case Year / अवमाननावाद वर्ष </label>
                                <input class="form-control" type="text" placeholder="Select Contempt Case Year"
                                    formControlName="contempt_year" id="contempt_year" autocomplete="off" minlength="4"
                                    maxlength="4">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input_grp form-group">
                                <label for="">Reason for Non-Compliance / पारित आदेश का विवरण जिसका अनुपालन ना करने पर
                                    अवमाननावाद दायर किया गया </label>
                                <input class="form-control" type="text" placeholder="Enter Reason for Non-Compliance"
                                    formControlName="contempt_reason">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Contempt Status / अवमाननावाद की अघतन स्थिति </label>
                                <input class="form-control" type="text" placeholder="Enter Contempt Status"
                                    formControlName="contempt_status">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input_grp form-group">
                                <label for="">Contempt Remark / अवमाननावाद अभ्युक्ति </label>
                                <input class="form-control" type="text" placeholder="Enter Contempt Remark"
                                    formControlName="contempt_remark">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="modal-footer">
                                <button type="submit" class="btn btn-primary" *ngIf="operation !== 'view'">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="dropDownModal" tabindex="-1" role="dialog" aria-labelledby="dropDownModal"
    aria-hidden="true" data-backdrop="static" #counsel>
    <div class="modal-dialog">
        <div class="modal-content" *ngIf="operation != 'view'">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel">{{ data.lable }}</h4>
            </div>
            <div class="modal-body">
                <div class="input-group d-flex mb-3">
                    <input type="text" class="form-control" #searchinp placeholder="Search"
                        aria-describedby="basic-addon2" (input)="onSearch(searchinp.value)">
                    <div class="input-group-append btn btn-primary">
                        <span class="input-group-text" id="basic-addon2">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
                <div class="grid-show">
                    <div class="options" *ngFor="let item of paginatedData; index as i">
                        <input type="checkbox" id="{{i}}" class="form-check-input"
                            (change)="addToBuffer(data.controlName, item._id)"
                            [checked]="this.buffer[data.controlName]?.includes(item._id)">
                        &nbsp;
                        <label for="{{i}}" style="text-transform: uppercase;">{{ item[data.nameKey] | trim:30 }}</label>
                    </div>
                </div>
                <div class="pagination">
                    <button class="btn-nav left-btn" (click)="prevPage()" [disabled]="currentPage === 1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="left-icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </button>
                    <div class="page-numbers">
                        <button class="btn-page" (click)="goToPage(1)"
                            [class.btn-selected]="1 === currentPage">1</button>
                        <span *ngIf="showDotsBefore" class="dots">...</span>

                        <ng-container *ngIf="displayPages.length > 1">
                            <ng-container *ngFor="let page of displayPages; let isFirst = first; let isLast = last">
                                <button *ngIf="!isLast && !isFirst" [class.btn-selected]="page === currentPage"
                                    (click)="goToPage(page)" class="btn-page">
                                    {{ page }}
                                </button>
                            </ng-container>
                        </ng-container>

                        <span *ngIf="showDotsAfter" class="dots">...</span>
                        <button class="btn-page" (click)="goToPage(totalPages)"
                            [class.btn-selected]="totalPages === currentPage">
                            {{ totalPages }}
                        </button>
                    </div>
                    <button class="btn-nav right-btn" (click)="nextPage()" [disabled]="currentPage === totalPages">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="right-icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="reset()">Close</button>
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveData(data.controlName)"
                    *ngIf="operation !== 'view'">Save</button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>