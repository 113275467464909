import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';
import { Subject, Subscription } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { Events } from 'src/app/services/events';
@Component({
  selector: 'app-district-wise-report',
  templateUrl: './district-wise-report.component.html',
  styleUrls: ['./district-wise-report.component.css']
})
export class DistrictWiseReportComponent implements OnInit, OnDestroy {
  getData: any[] = [];
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('noteModal') noteModal: any;
  year: any = [];
  getDataO: any;
  saveForm: any;
  isValidFormSubmitted!: boolean;
  state!: Subscription;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  getDataDist: any;
  constructor(
    private CaseS: CaseService,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {


    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   ordering: false,
    //   searching: true,
    //   processing: true,
    //   dom: 'Blfrtip',
    //   "info": true,
    //   "lengthMenu": [[10, 100, 500, -1], [10, 100, 500, "All"]],
    // };
    this.spinner.show();
    let userID = localStorage.getItem("id");

    // GetCaseData
    this.state = this.CaseS.GetCaseDatas(userID).subscribe((data: any) => {
      // this.ngZone.run(() => {
      this.getData = data.resultData;
      // console.log(this.getData, " : Data")
      this.dtOptions = {
        pagingType: 'full_numbers',
        ordering: false,
        searching: true,
        processing: true,
        dom: 'Blfrtip',
        "info": true,
        "lengthMenu": [[10, 100, 500, -1], [10, 100, 500, "All"]],
      };
      let _self = this;
      setTimeout(function () {
        _self.dtTrigger.next();
        _self.spinner.hide();
      }.bind(this));

    });
    this.districtData();
    this.orgData();
    // });
  }

  // ngOnInit(): void {
  //   this.districtData();
  //   this.orgData();

  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     // pageLength: 10,
  //     ordering: false,
  //     searching: true,
  //     processing: true,
  //     // dom: 'lfrtip', 
  //     dom: 'Blfrtip',
  //     "info": true,
  //     "lengthMenu": [[10, 100, 500, -1], [10, 100, 500, "All"]],
  //   };
  //   // this.events.subscribe('reports', (resp: any) => {
  //   
  //   //   if (resp) {

  //   //     this.getData = resp;
  //   //     this.dtTrigger.next();
  //   //    
  //   //   }

  //   // });
  //   this.spinner.show();
  //   let userID = localStorage.getItem("id");
  //   this.CaseS.GetCaseData(userID).subscribe((data: any) => {

  //     this.getData = data.resultData;
  //     this.dtTrigger.next();
  //     this.spinner.hide();
  //   });
  // }
  getDataDistrict(y: any) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {


      if (y.target.value != '') {
        dtInstance
          .column(1)
          .search('^' + y.target.value + '$', true, false, true)
          .draw();
      } else if (y.target.value == 'other') {
        // console.log("Choosen Other option!!")
      }
      else {
        window.location.reload()
      }

    });
  }

  getDataDepartment(d: any) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      if (d.target.value != '') {
        dtInstance.column(2)
          .search('^' + d.target.value + '$', true, false, true)
          .draw();
      }
      else {
        window.location.reload()
      }
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn.dataTable.ext.search.pop();
    // this.events.destroy('reports');
    this.state.unsubscribe();
  }




  orgData() {
    this.MasterS.GetOrgdata().subscribe((data: any) => {

      this.getDataO = data.resultData;
    });
  }
  viewdata() {
    let userID = localStorage.getItem("id");
    this.CaseS.GetCaseData(userID).subscribe((data: any) => {

      this.getData = data.resultData;
    });
  }


  districtData() {
    this.MasterS.GetDistrictata().subscribe((data: any) => {

      this.getDataDist = data.resultData;
    });
  }

}