<aside id="aside" class="app-aside hidden-xs bg-blue">
    <div class="aside-wrap" id="sidebar">
        <div class="navi-wrap">
            <nav ui-nav class="navi clearfix" id="leftmenuinnerinner">
                <ul class="nav">
                    <li class="hidden-folded padder m-t m-b-sm text-10">
                        <span>NAVIGATIONS</span>
                    </li>
                    <li>
                        <a routerLink="/dashboard" routerLinkActive="active">
                            <i class="glyphicon glyphicon-th-large"></i>
                            <span>Dashboard </span>
                        </a>
                    </li>

                    <li class="hidden-folded padder m-t m-b-sm text-10">
                        <span>CASE MANAGEMENT</span>
                    </li>
                    <ng-container *ngIf="roledata.caseView">
                        <li>
                            <a class="case-list" routerLink="/dashboard/is-contempt-yes-report"
                                routerLinkActive="active">
                                <i class="fa fa-calculator"></i>
                                <span> &nbsp;&nbsp; Contempt</span>
                            </a>
                        </li>
                        <li>
                            <a class="case-list" routerLink="/dashboard/compliance-list-disposed"
                                routerLinkActive="active">
                                <i class="fa fa-calculator"></i>
                                <span> &nbsp;&nbsp; Compliance</span>
                            </a>
                        </li>
                        <li>
                            <a class="case-list" routerLink="/dashboard/slp-list" routerLinkActive="active">
                                <i class="fa fa-calculator"></i>
                                <span> &nbsp;&nbsp; SLP</span>
                            </a>
                        </li>
                    </ng-container>

                    <ng-container *ngIf="roledata.caseView">
                        <li>
                            <a class="case-list" routerLink="/dashboard/case-list" routerLinkActive="active">
                                <i class="fa fa-calculator"></i>
                                <span> &nbsp;&nbsp; Cases</span>
                            </a>
                        </li>
                        <li>
                            <a class="case-list" routerLink="/dashboard/add-case" routerLinkActive="active">
                                <i class="fa fa-calculator"></i>
                                <span> &nbsp;&nbsp; Add Case</span>
                            </a>
                        </li>
                        <li>
                            <a class="case-list" routerLink="/dashboard/affidavit-filed-list" routerLinkActive="active">
                                <i class="fa fa-calculator"></i>
                                <span> &nbsp;&nbsp; Affidavit </span>
                            </a>
                        </li>
                    </ng-container>
                    <li *ngIf="roledata.caseDetailsView">
                        <a routerLink="/dashboard/case-details" routerLinkActive="active">
                            <i class="fa fa-id-badge"></i>
                            <span> &nbsp;&nbsp; Case Details</span>
                        </a>
                    </li>
                    <li class="line dk"></li>
                    <li class="hidden-folded padder m-t m-b-sm text-10" *ngIf="roledata.reportView">
                        <span>REPORTS</span>
                    </li>

                    <li *ngIf="roledata.reportView">
                        <a routerLink="/dashboard/department-wise-report" routerLinkActive="active">
                            <i class="fa fa-id-badge"></i>
                            <span>Department-wise</span>
                        </a>
                    </li>

                    <li *ngIf="roledata.reportView">
                        <a routerLink="/dashboard/district-wise-new-report" routerLinkActive="active">
                            <i class="fa fa-calculator"></i>
                            <span>District-wise</span>
                        </a>
                    </li>

                    <li *ngIf="roledata.reportView">
                        <a routerLink="/dashboard/year-wise-new-report" routerLinkActive="active">
                            <i class="fa fa-calculator"></i>
                            <span>Year-wise</span>
                        </a>
                    </li>
                    <li *ngIf="roledata.reportView">
                        <a routerLink="/dashboard/court-wise-new-report" routerLinkActive="active">
                            <i class="fa fa-id-badge"></i>
                            <span>Court-wise</span>
                        </a>
                    </li>
                    <li *ngIf="roledata.reportView">
                        <a routerLink="/dashboard/section-wise-new-report" routerLinkActive="active">
                            <i class="fa fa-calculator"></i>
                            <span>Section-wise</span>
                        </a>
                    </li>
                    <li *ngIf="roledata.reportView">
                        <a routerLink="/dashboard/a-1-report" routerLinkActive="active">
                            <i class="fa fa-calculator"></i>
                            <span>a-1</span>
                        </a>
                    </li>
                    <li *ngIf="roledata.reportView">
                        <a routerLink="/dashboard/b-1-report" routerLinkActive="active">
                            <i class="fa fa-calculator"></i>
                            <span>b-1</span>
                        </a>
                    </li>
                    <li class="line dk"></li>
                    <li class="hidden-folded padder m-t m-b-sm text-10">
                        <span>PEOPLE</span>
                    </li>
                    <li *ngIf="roledata.userView">
                        <a routerLink="/dashboard/list-user" routerLinkActive="active">
                            <i class="fa fa-power-off"></i>
                            <span>Users </span>
                        </a>
                    </li>
                    <li *ngIf="roledata.roleView">
                        <a routerLink="/dashboard/role-permission-list" routerLinkActive="active">
                            <i class="fa fa-power-off"></i>
                            <span>User Role Management </span>
                        </a>
                    </li>
                    <a style="padding: 10px 20px;">
                        <i class="fa fa-power-off"></i>
                        <span>Peoples</span>
                    </a>
                    <ul style="display: none;">
                        <li *ngIf="roledata.petitionerView">
                            <a routerLink="/dashboard/petitioner-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Petitioners </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.counselView">
                            <a routerLink="/dashboard/counsel-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Councels </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.respondentView">
                            <a routerLink="/dashboard/respondent-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Respondent</span>
                            </a>
                        </li>
                    </ul>
                    <li class="line dk"></li>
                    <li class="hidden-folded padder m-t m-b-sm text-10" *ngIf="roledata.masterView">
                        <span>MASTER CONFIGURATION</span>
                    </li>

                    <a style="padding: 10px 20px;">
                        <i class="fa fa-power-off"></i>
                        <span>Administrative</span>
                    </a>
                    <ul style="display: none;">
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/task-type-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Task Type</span>
                            </a>
                        </li>

                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/expected-action-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Expected Action Type</span>
                            </a>
                        </li>

                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/ho-section-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Head Office </span>
                            </a>
                        </li>

                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/govt-section-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Section </span>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="/unit-list">
                                <i class="fa fa-power-off"></i>
                                <span>Unit </span>
                            </a>
                        </li> -->
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/court-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Courts </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/orgnaisation-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Departments </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/sub-org-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Organization/CE </span>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="/department-list">
                                <i class="fa fa-power-off"></i>
                                <span>Departments </span>
                            </a>
                        </li> -->
                        <!-- <li>
                            <a href="/ce-list">
                                <i class="fa fa-power-off"></i>
                                <span>CE </span>
                            </a>
                        </li> -->
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/se-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>SE </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/ee-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>EE </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/case-type-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Case Type </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/case-status-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Case Status </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/case-subject-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Case Subject </span>
                            </a>
                        </li>
                        <li *ngIf="roledata.masterView">
                            <a routerLink="/dashboard/case-classification-list" routerLinkActive="active">
                                <i class="fa fa-power-off"></i>
                                <span>Case Classification </span>
                            </a>
                        </li>
                    </ul>

                    <li class="line dk"></li>
                    <li class="hidden-folded padder m-t m-b-sm text-10" *ngIf="roledata.resourceView">
                        <span>RESOURCES</span>
                    </li>
                    <li>
                        <a routerLink="/dashboard/external-link-list" routerLinkActive="active"
                            *ngIf="roledata.resourceView">
                            <i class="fa fa-calculator"></i>
                            <span>External Links</span>
                        </a>
                    </li>

                    <li>
                        <a routerLink="/dashboard/resource-document-list" routerLinkActive="active"
                            *ngIf="roledata.resourceView">
                            <i class="fa fa-calculator"></i>
                            <span>Documents</span>
                        </a>
                    </li>
                    <li class="line dk"></li>
                    <li class="hidden-folded padder m-t m-b-sm text-10">
                        <span>DEPARTMENT</span>
                    </li>


                    <li>
                        <a (click)="logout()">
                            <i class="fa fa-power-off"></i>
                            <span>Logout </span>
                        </a>
                    </li>
                    <li class="line dk"></li>

                </ul>
            </nav>
            <div class="aside-footer wrapper-sm poppins">
                <div class="text-center-folded text-center">

                    <p class="hidden-folded text-xs m-b-xs">Need help ? <a href="https://www.troology.com/"
                            target="_blank" class="font-weight-600">Contact us</a></p>
                    <p class="hidden-folded text-xs m-b-lg"><a href="https://www.troology.com/" target="_blank"><i
                                class="fa fa-bug"></i> Report a bug?</a></p>
                    <img src="./assets/img/icici.png" class="center-block img-responsive m-b-xs" width="75px">
                    <p class="hidden-folded text-10">Version 1.2.3</p>
                    <hr style="margin: 10px 0;">
                    <p class="hidden-folded text-10 font-weight-600 text-uppercase m-b-xs"><a href="">Support
                            Manual</a></p>
                    <p class="hidden-folded text-10 font-weight-600 text-uppercase m-b-xs"><a href="">Copyright
                            Information</a></p>
                </div>
            </div>
        </div>
    </div>
</aside>