<div id="content" class="app-content" role="main">
  <div class="app-content-body ">
    <!-- main header -->
    <div class="bg-blue lter b-b wrapper-md">
      <div class="row">
        <div class="col-sm-6 col-xs-12">
          <h3 class="m-n font-medium"> Organization/CE List View</h3>
        </div>
        <div class="col-sm-6 text-right poppins hidden-xs">
          <!-- <button class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" > -->
          <a routerLink="/dashboard/sub-org-add" routerLinkActive="active"
            class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" *ngIf="roledata.masterCreate"><i
              class="fa fa-plus"></i> Add Organaization/CE </a>
          <!-- </button> -->
        </div>
      </div>
    </div>
    <!-- / main header -->
    <div class="row">
      <div class="col-md-12">
        <div class="card no-shadow">
          <div class="tab-content bg-seashell">
            <div role="tabpanel" class="tab-pane active g-py-0">
              <div class="row row-xs">
                <div class="row" style="margin-top: 1rem; margin-bottom: .5rem;">

                </div>
                <table class="table bg-white newTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                  id="portal-drivers">
                  <thead>
                    <tr role="row">
                      <th>#</th>
                      <th>Code</th>
                      <th>Department</th>
                      <th>Organization/CE Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row" *ngFor="let data of getData; let i= index;">
                      <td>{{ [i+1] }}</td>
                      <td>{{data.sub_org_code}}</td>
                      <td>{{data?.result[0]?.org_name}}</td>
                      <!-- <td>{{data.org_id}}</td>  -->
                      <td>{{data.sub_org_name}}</td>
                      <td td>
                        <div class="btn-group dropdown">
                          <button class="btn btn-default w-xxs btn-sm r-2x" data-toggle="dropdown"
                            aria-expanded="false"><i class="fa fa-cog g-px-5  text-info text-sm"></i> <span class=""><i
                                class="fa fa-caret-down"></i></span></button>
                          <ul class="dropdown-menu">
                            <li *ngIf="roledata.masterEdit"><a routerLink="/dashboard/sub-org-update/{{data._id}}"
                                routerLinkActive="active"><i class="fa fa-edit"></i> Edit </a></li>
                            <li *ngIf="roledata.masterDelete"><a (click)="deleteUser(data._id)"><i
                                  class="fa fa-trash-o"></i> Delete </a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>