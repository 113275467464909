import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-view-button',
  templateUrl: './view-button.component.html',
  styleUrls: ['./view-button.component.css']
})
export class ViewButtonComponent implements OnInit {
  user: any;
  userID: any;
  roledata: any;
  public value!: string;
  public logo_name!: string;

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
  }

  refresh(params: ICellRendererParams): boolean {
    this.value = params.value;
    return true;
  }

  ngOnInit(): void {
    this.user = localStorage.getItem("userdata");
    this.userID = localStorage.getItem("id");
    this.roledata = JSON.parse(this.user).role;
  }

}
